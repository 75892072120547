/* eslint-disable react-hooks/exhaustive-deps */
import BakesSidebar from "components/modules/Navbar/MobileNavbar/BakesSidebar";
import CakesSidebar from "components/modules/Navbar/MobileNavbar/CakesSidebar";
import GiftsSidebar from "components/modules/Navbar/MobileNavbar/GiftsSidebar";
import { MobileSidebar } from "components/modules/Navbar/MobileNavbar/MobileSidebar";
import { LOGIN } from "components/utils/constants/routeConstants";
import useCheckDevice from "hooks/useCheckDevice";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useEffect, useState, lazy } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const DesktopHeader = lazy(
  () => import("components/modules/Navbar/DesktopNavbar/DesktopHeader"),
);
const MobileHeader = lazy(
  () => import("components/modules/Navbar/MobileNavbar/MobileHeader"),
);

const Auth = ({ Component, isPrivate, hideHeader }) => {
  const navigate = useNavigate();
  const [isDesktop] = useCheckDevice();
  const [overlay, setOverlay] = useState(false);
  const { toggleSidebar } = useSelector((state) => state.sidebar);
  const [isOpenMiniSidebar, setIsOpenMiniSidebar] = useState("");
  const authToken = Cookies.get("token");

  useEffect(() => {
    if (isPrivate && !authToken) {
      navigate(LOGIN);
    }
  }, []);

  const toggleMiniSidebar = (miniSidebarState) => {
    setIsOpenMiniSidebar(miniSidebarState);
  };

  function renderNavbar() {
    return isDesktop ? (
      <DesktopHeader setOverlay={setOverlay} />
    ) : (
      <MobileHeader />
    );
  }

  function renderMiniSidebar() {
    let component;
    if (!toggleSidebar) {
      return null;
    }
    switch (isOpenMiniSidebar) {
      case "Gifts":
        component = <GiftsSidebar toggleMiniSidebar={toggleMiniSidebar} />;
        break;
      case "Cakes":
        component = <CakesSidebar toggleMiniSidebar={toggleMiniSidebar} />;
        break;
      case "Bakes":
        component = <BakesSidebar toggleMiniSidebar={toggleMiniSidebar} />;
        break;
      default:
        component = (
          <MobileSidebar
            isOpenMiniSidebar={isOpenMiniSidebar}
            toggleSidebar={toggleSidebar}
            toggleMiniSidebar={toggleMiniSidebar}
          />
        );
    }
    return component;
  }

  return (
    <>
      {!hideHeader && renderNavbar()}
      {overlay && <div className="navbar-overlay" />}
      {renderMiniSidebar() || <Component />}
    </>
  );
};

Auth.defaultProps = {
  isPrivate: false,
  hideHeader: false,
};

Auth.propTypes = {
  Component: PropTypes.elementType.isRequired,
  isPrivate: PropTypes.bool,
  hideHeader: PropTypes.bool,
};

export default Auth;
