import FaqAndContactUsHeader from "components/generic/FaqAndContactUsHeader/FaqAndContactUsHeader";
import "./Faq.css";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Icon,
} from "semantic-ui-react";
import { useState } from "react";
import {
  faqQuestionsList,
  faqSections,
} from "components/utils/constants/messageConstants";
import useCheckDevice from "hooks/useCheckDevice";
import { Helmet } from "react-helmet";
import ChoosePinchBakehouse from "../HomePage/choosePinchBakehouse/ChoosePinchBakehouse";
import Footer from "../Footer/Footer";

const Faq = () => {
  const [isDesktop] = useCheckDevice();
  const [activeIndex, setActiveIndex] = useState("");

  const handleOnClick = (e, titleProps) => {
    const { index } = titleProps;
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <div>
      <Helmet>
        <title>FAQ</title>
        <meta
          name="description"
          content="We're a Halal-certified bakery that delivers throughout Singapore. Wanna know more about Pinch? Click here."
        />
      </Helmet>
      <FaqAndContactUsHeader
        title="FAQ"
        text="You may find answers to frequent questions below"
        sections={faqSections}
      />
      <div className="flex mt-16 gap-10">
        {isDesktop && (
          <div className="grow-0  flex  items-left gap-2 flex-col pl-8 xl:pl-60">
            {/* <div className=""> */}
            <h2 className="title-60 !text-left">Looking for help?</h2>
            <a
              href="#product-storage-title"
              id="product-storage"
              className="para-25 !font-[600] !text-left hover:text-black"
            >
              Storage and Delivery
            </a>

            <a
              href="#product-faq-title"
              id="product-faq"
              className="para-25 !font-[600] !text-left hover:text-black"
            >
              Product
            </a>
            <a
              href="#product-order-title"
              id="product-order"
              className="para-25 !font-[600] !text-left hover:text-black"
            >
              Order and Cancellations
            </a>
          </div>
        )}

        <div className="grow-[3] faqs-container mt-5 mb-10 pr-8">
          <Accordion styled className="!bg-transparent !border-none mt-3">
            {faqQuestionsList.map(
              ({ id, faqSectionTitle, accordianContent }) => {
                return (
                  <>
                    <p
                      id={id}
                      className="para-25 !font-[600] !text-left !mt-10"
                    >
                      {faqSectionTitle}
                    </p>

                    {accordianContent.map(
                      ({
                        title,
                        content1,
                        content2,
                        content3,
                        content4,
                        contentList,
                      }) => {
                        return (
                          <>
                            <AccordionTitle
                              active={activeIndex === title}
                              index={title}
                              onClick={handleOnClick}
                              className="para-25 !mt-4"
                            >
                              <span className="flex justify-between text-left !font-[500] ">
                                {title}
                                <Icon name="dropdown" className="!mt-2" />
                              </span>
                            </AccordionTitle>
                            <AccordionContent active={activeIndex === title}>
                              <p className="text-18 !font-[400]">{content1}</p>
                              {content2 && (
                                <p className="text-18 !font-[400] mt-2">
                                  {content2}
                                </p>
                              )}

                              {contentList?.length > 0 && (
                                <ul className="mt-2">
                                  {contentList.map((point) => {
                                    return (
                                      <li className="text-18 !font-[400]">
                                        {point}
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}

                              {content3 && (
                                <p className="text-18 !font-[400] mt-3">
                                  {content3}
                                </p>
                              )}
                              {content4 && (
                                <p className="text-18 !font-[400] mt-3">
                                  {content4}
                                </p>
                              )}
                            </AccordionContent>
                            <hr className="border-[#7c7c7c]  w-full" />
                          </>
                        );
                      },
                    )}
                  </>
                );
              },
            )}
          </Accordion>
        </div>
      </div>

      <ChoosePinchBakehouse />
      <Footer />
    </div>
  );
};

export default Faq;
