import Proptypes from "prop-types";
import "./FaqAndContactUsHeader.css";
import { Breadcrumb } from "semantic-ui-react";

const FaqAndContactUsHeader = ({ title, text, sections }) => {
  return (
    <header className="bg-primary-violet-20 h-[200px] relative px-8 xl:px-60 ">
      <Breadcrumb
        className="absolute left-7 md:left-20 top-3 family-inter font-light text-red bread-desk bread-desk-top"
        icon="right angle"
        sections={sections}
      />
      <div className="flex items-center md:items-start justify-center h-full relative flex-col gap-2 ">
        <h1 className="title-60">{title}</h1>
        <p className="para-25">{text}</p>
      </div>
    </header>
  );
};
export default FaqAndContactUsHeader;

FaqAndContactUsHeader.propTypes = {
  title: Proptypes.string.isRequired,
  text: Proptypes.string.isRequired,
  sections: Proptypes.string.isRequired,
};
