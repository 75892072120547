import PropTypes from "prop-types";

const GenericButton = ({
  btnLabel,
  btnWidth = "w-[200px]",
  onClick,
  styles = {},
  ...rest
}) => {
  return (
    <button
      className={`bg-black md:text-[1.7rem] text-[1.2rem] tracking-tight text-white hover:text-black hover:bg-primary-violet md:w-[260px] ${btnWidth} py-3`}
      type="button"
      color="black"
      onClick={onClick}
      size="huge"
      style={styles}
      {...rest}
    >
      {btnLabel}
    </button>
  );
};

GenericButton.defaultProps = {
  styles: {},
  btnWidth: "w-[200px]",
  onClick: () => null,
};

GenericButton.propTypes = {
  btnLabel: PropTypes.string.isRequired,
  btnWidth: PropTypes.string,
  onClick: PropTypes.func,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default GenericButton;
