import { Image } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { UilTimes, UilAngleLeftB } from "@iconscout/react-unicons";
import { toggle } from "features/sidebarSlice";

export const SidebarHeader = ({ toggleMiniSidebar, isOpenMiniSidebar }) => {
  const dispatch = useDispatch();

  const onCloseSidebar = () => {
    toggleMiniSidebar("");
    dispatch(toggle(false));
  };

  const onBackSidebar = () => {
    toggleMiniSidebar("");
    dispatch(toggle(true));
  };

  return (
    <div className="flex justify-between gap-6 mt-8 mobile-inter-15 items-center mb-16">
      <button
        type="button"
        onClick={onBackSidebar}
        className={`tracking-normal font-normal flex justify-center items-center gap-1 ${
          isOpenMiniSidebar !== ""
            ? "visibility: visible"
            : "visibility: collapse"
        } `}
      >
        <UilAngleLeftB className="left-arrow-icon" />
        <div>Back</div>
      </button>

      <Image
        className="pinch-logo"
        alt="Dummy Logo"
        src="/assets/images/logo/pinch-logo-black.svg"
        size="small"
      />

      <div className="flex justify-center items-center gap-1">
        <button
          type="button"
          onClick={onCloseSidebar}
          className="tracking-normal font-normal"
        >
          Close
        </button>
        <UilTimes onClick={onCloseSidebar} className="close-icon" />
      </div>
    </div>
  );
};

SidebarHeader.propTypes = {
  toggleMiniSidebar: PropTypes.func.isRequired,
  isOpenMiniSidebar: PropTypes.string.isRequired,
};
