import {
  cancellationOfOrders,
  generalTermsAndConditions,
  othergeneral,
  refundPolicy,
  release,
  termsAndConditions,
  termsConditionsSections,
  useOfWebsiteTerms,
} from "components/utils/constants/messageConstants";
import FaqAndContactUsHeader from "components/generic/FaqAndContactUsHeader/FaqAndContactUsHeader";
// import { faqQuestionsList } from "components/utils/constants/messageConstants";
// import useCheckDevice from "hooks/useCheckDevice";
import ChoosePinchBakehouse from "../HomePage/choosePinchBakehouse/ChoosePinchBakehouse";
import Footer from "../Footer/Footer";

const TermsConditions = () => {
  return (
    <div>
      <FaqAndContactUsHeader
        title="Terms & Conditions"
        text=""
        sections={termsConditionsSections}
      />
      <div className="privacy-policy-container px-10 py-20 xl:px-60 xl:py-32">
        <div className="pp-container-one">
          <h2 className="title-40 !text-left mb-2">TERMS OF WEBSITE USE</h2>
          <ul>
            {useOfWebsiteTerms.map((list) => {
              return <li className="para-25 !text-left mt-1">• {list}</li>;
            })}
          </ul>
        </div>
        <div className="pp-container-two mt-3">
          <h2 className="title-40 !text-left mb-2">TERMS AND CONDITIONS</h2>
          <ul>
            {termsAndConditions.map((list) => {
              return <li className="para-25 !text-left mt-1">• {list}</li>;
            })}
          </ul>
        </div>
        <div className="pp-container-three mt-3">
          <h2 className="title-40 !text-left mb-2">GENERAL</h2>
          <ul>
            {generalTermsAndConditions.map((list) => {
              return <li className="para-25 !text-left mt-1">• {list}</li>;
            })}
          </ul>
        </div>
        <div className="pp-container-three mt-3">
          <h2 className="title-40 !text-left mb-2">
            CANCELLATION OF CAKE ORDERS
          </h2>
          <ul>
            {cancellationOfOrders.map((list) => {
              return <li className="para-25 !text-left mt-1">• {list}</li>;
            })}
          </ul>
        </div>
        <div className="pp-container-three mt-3">
          <h2 className="title-40 !text-left mb-2">
            CANCELLATION / REFUND POLICY
          </h2>
          <ul>
            {refundPolicy.map((list) => {
              return <li className="para-25 !text-left mt-1">• {list}</li>;
            })}
          </ul>
        </div>
        <div className="pp-container-three mt-3">
          <h2 className="title-40 !text-left mb-2">
            RISK ACKNOWLEDGMENT & RELEASE
          </h2>
          <ul>
            {release.map((list) => {
              return <li className="para-25 !text-left mt-1">• {list}</li>;
            })}
          </ul>
        </div>
        <div className="pp-container-three mt-3">
          <h2 className="title-40 !text-left mb-2">
            OTHER GENERAL INFORMATION
          </h2>
          <ul>
            {othergeneral.map((list) => {
              return <li className="para-25 !text-left mt-1">• {list}</li>;
            })}
          </ul>
        </div>
      </div>

      <ChoosePinchBakehouse />
      <Footer />
    </div>
  );
};

export default TermsConditions;
