import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { Loader } from "semantic-ui-react";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import store from "./app/store";
import "react-toastify/dist/ReactToastify.css";

if (process.env.REACT_APP_ENV === "production") {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Suspense fallback={<Loader />}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            style={{ fontSize: "1rem" }}
          />
          <App />
        </Suspense>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
