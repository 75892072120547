import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addressSidebar: false,
};

export const addressSlice = createSlice({
  name: "addressSidebar",
  initialState,
  reducers: {
    toggleAddressBar: (state, { payload }) => {
      const addressState = state;

      addressState.addressSidebar = payload;
    },
  },
});

export const { toggleAddressBar } = addressSlice.actions;
export default addressSlice.reducer;
