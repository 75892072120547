import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {},
};

export const userDetailsSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    userDetails: (state, { payload }) => {
      const userState = state;
      userState.userInfo = payload;
    },
  },
});

export const { userDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
