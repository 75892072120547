import "./App.css";
import "./components/modules/HomePage/HomePage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import "./components/modules/Navbar/MobileNavbar/MobileSidebar.css";
import { Routes, Route } from "react-router-dom";
import {
  ACCOUNT,
  ADDRESS_BOOK,
  ALL_CAKES,
  CAKE_CONCIERGE,
  CAKE_DETAILS,
  CART_PAGE,
  CHANGE_PWD,
  FORGOT_PWD,
  LOGIN,
  ORDER_CONFIRMATION,
  REGISTER,
  RESET_PWD,
  CHECKOUT,
  PAYMENT_FAIL,
  ORDER_DETAILS,
  FAQ,
  CONTACT_US,
  ALL_BAKES,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
  BLOGS,
  ARTICLE_ONE,
  ARTICLE_TWO,
  ARTICLE_THREE,
  ARTICLE_FOUR,
  ARTICLE_FIVE,
} from "components/utils/constants/routeConstants";
import Login from "components/modules/Auth/Login";
import Register from "components/modules/Auth/Register";
import Auth from "routes/Auth";
import ResetPassword from "components/modules/Auth/ResetPassword";
import ForgotPassword from "components/modules/Auth/ForgotPassword";
import ChangePassword from "components/modules/Auth/ChangePassword";
import HowMayIHelpYou from "components/modules/HowMayIHelpYou";
import Cart from "components/modules/Cart";
import YourCartPage from "components/modules/YourCartPage";
import OrderConfirmation from "components/modules/Order/OrderConfirmation";
import PageNotFound from "components/generic/PageNotFound";
import PaymentFail from "components/modules/Order/PaymentFail";
import Checkout from "components/modules/Checkout";
import OrderDetails from "components/modules/Order/OrderDetails";
import Faq from "components/modules/FAQ/Faq";
import ContactUs from "components/modules/ContactUs";
import AllBakes from "components/modules/AllBakes";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import PrivacyPolicy from "components/modules/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "components/modules/TermsConditions/TermsConditions";
import BlogPage from "components/modules/Blogs/BlogPage";
import ArticleOne from "components/modules/Blogs/ArticleOne";
import ArticleTwo from "components/modules/Blogs/ArticleTwo";
import ArticleThree from "components/modules/Blogs/ArticleThree";
import ArticleFour from "components/modules/Blogs/ArticleFour";
import ArticleFive from "components/modules/Blogs/ArticleFive";
import ProductDetails from "./components/modules/ProductDetails";
import Account from "./components/modules/Account";
import AllCakes from "./components/modules/AllCakes";
import AddressBook from "./components/modules/Address/AddressBook";

// Components imports
const Loader = lazy(() => import("components/generic/Loader"));
const HomePage = lazy(() => import("components/modules/HomePage"));

// App Main Function
function App() {
  const { cartSidebar } = useSelector((state) => state.cart);
  // function renderSearch() {
  //   return searchSidebar && <SearchMobile />;
  // }

  return (
    <Suspense fallback={<Loader />}>
      {cartSidebar && <Cart />}
      <Routes>
        <Route path="/" element={<Auth Component={HomePage} />} />
        <Route path={ALL_CAKES} element={<Auth Component={AllCakes} />} />
        <Route path={ALL_BAKES} element={<Auth Component={AllBakes} />} />
        <Route
          path={CAKE_DETAILS}
          element={<Auth Component={ProductDetails} />}
        />
        <Route path={LOGIN} element={<Auth Component={Login} />} />
        <Route path={REGISTER} element={<Auth Component={Register} />} />
        <Route
          path={ACCOUNT}
          element={<Auth Component={Account} isPrivate />}
        />
        <Route
          path={ADDRESS_BOOK}
          element={<Auth Component={AddressBook} isPrivate />}
        />
        <Route
          path={CHANGE_PWD}
          element={<Auth Component={ChangePassword} isPrivate />}
        />
        <Route path={RESET_PWD} element={<Auth Component={ResetPassword} />} />
        <Route
          path={FORGOT_PWD}
          element={<Auth Component={ForgotPassword} />}
        />
        <Route
          path={CAKE_CONCIERGE}
          element={<Auth Component={HowMayIHelpYou} />}
        />
        <Route path={CART_PAGE} element={<Auth Component={YourCartPage} />} />
        <Route
          path={CHECKOUT}
          element={<Auth Component={Checkout} hideHeader />}
        />
        <Route
          path={ORDER_CONFIRMATION}
          element={<Auth Component={OrderConfirmation} isPrivate />}
        />
        <Route
          path={PAYMENT_FAIL}
          element={<Auth Component={PaymentFail} isPrivate />}
        />
        <Route
          path={ORDER_DETAILS}
          element={<Auth Component={OrderDetails} isPrivate />}
        />
        <Route path={FAQ} element={<Auth Component={Faq} />} />
        <Route path={CONTACT_US} element={<Auth Component={ContactUs} />} />
        <Route
          path={PRIVACY_POLICY}
          element={<Auth Component={PrivacyPolicy} />}
        />
        <Route
          path={TERMS_CONDITIONS}
          element={<Auth Component={TermsConditions} />}
        />
        <Route path={BLOGS} element={<Auth Component={BlogPage} />} />
        <Route path={ARTICLE_ONE} element={<Auth Component={ArticleOne} />} />
        <Route path={ARTICLE_TWO} element={<Auth Component={ArticleTwo} />} />
        <Route
          path={ARTICLE_THREE}
          element={<Auth Component={ArticleThree} />}
        />
        <Route path={ARTICLE_FOUR} element={<Auth Component={ArticleFour} />} />
        <Route path={ARTICLE_FIVE} element={<Auth Component={ArticleFive} />} />
        <Route path="/loader" element={<Auth Component={Loader} />} />
        <Route path="/*" element={<Auth Component={PageNotFound} />} />
      </Routes>
      <FloatingWhatsApp
        accountName="Pinch Bakehouse"
        phoneNumber="6583806722"
        avatar="/assets/images/logo/pinch-logo-black.svg"
        notification
        notificationSound
      />
    </Suspense>
  );
}

export default App;
