import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchSidebar: false,
};

export const searchSlice = createSlice({
  name: "searchSidebar",
  initialState,
  reducers: {
    search: (state, { payload }) => {
      const searchState = state;
      searchState.searchSidebar = payload;
    },
  },
});

export const { search } = searchSlice.actions;
export default searchSlice.reducer;
