import {
  policySections,
  useOfYourInfo,
} from "components/utils/constants/messageConstants";
import FaqAndContactUsHeader from "components/generic/FaqAndContactUsHeader/FaqAndContactUsHeader";
import "./PrivacyPolicy.css";
// import { faqQuestionsList } from "components/utils/constants/messageConstants";
// import useCheckDevice from "hooks/useCheckDevice";
import ChoosePinchBakehouse from "../HomePage/choosePinchBakehouse/ChoosePinchBakehouse";
import Footer from "../Footer/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <FaqAndContactUsHeader
        title="Privacy Policy"
        text=""
        sections={policySections}
      />
      <div className="privacy-policy-container px-10 py-20 xl:px-60 xl:py-32">
        <div className="pp-container-one">
          <h2 className="title-40 !text-left mb-2">
            COLLECTION OF YOUR INFORMATION
          </h2>
          <p className="para-25 !text-left mt-3">
            We may collect information about you in a variety of ways. The
            information we may collect on the Site (www.pinchbakehouse.com)
            includes:
          </p>
          <h3 className="para-25 !text-left mt-2">PERSONAL DATA</h3>
          <p className="para-25 !text-left mt-2">
            Personally identifiable information, such as your name, shipping
            address, email address, and telephone number, and demographic
            information, such as your age, gender, hometown, and interests, that
            you voluntarily give to us when you register with the Site, or when
            you choose to participate in various activities related to the Site,
            such as online chat, online forms, polls, message boards and
            subscription to newsletters and promotional emails. You are under no
            obligation to provide us with personal information of any kind,
            however your refusal to do so may prevent you from using certain
            features of the Site.
          </p>
          <h3 className="para-25 !text-left mt-2">DERIVATIVE DATA</h3>
          <p className="para-25 !text-left mt-2">
            Information our servers automatically collect when you access the
            Site, such as your IP address, your browser type, your operating
            system, your access times, and the pages you have viewed directly
            before and after accessing the Site.
          </p>
          <h3 className="para-25 !text-left mt-2">FINANCIAL DATA</h3>
          <p className="para-25 !text-left mt-2">
            Financial information, such as data related to your payment method
            (e.g. valid credit card number, card brand, expiration date) that we
            may collect when you purchase, order, return, exchange, or request
            information about our services from the Site. [We store only very
            limited, if any, financial information that we collect. Otherwise,
            all financial information is stored by our payment processor,
            [HitPay App], [Stripe Payments], and you are encouraged to review
            their privacy policy and contact them directly for responses to your
            questions.]
          </p>
          <h3 className="para-25 !text-left mt-2">DATA FROM SOCIAL NETWORKS</h3>
          <p className="para-25 !text-left mt-2">
            User information from social networking sites, such as [Facebook,
            Google, Instagram], including your name, your social network
            username, location, gender, birth date, email address, profile
            picture, and public data for contacts, if you connect your account
            to such social networks.
          </p>
          <h3 className="para-25 !text-left mt-2">MOBILE DEVICE DATA</h3>
          <p className="para-25 !text-left mt-2">
            Device information, such as your mobile device ID, model, and
            manufacturer, and information about the location of your device, if
            you access the Site from a mobile device.
          </p>
          <h3 className="para-25 !text-left mt-2">THIRD-PARTY DATA</h3>
          <p className="para-25 !text-left mt-2">
            Information from third parties, such as personal information or
            network friends, if you connect your account to the third party and
            grant the Site permission to access this information.
          </p>
          <h3 className="para-25 !text-left mt-2">
            DATA FROM CONTESTS, GIVEAWAYS, AND SURVEYS
          </h3>
          <p className="para-25 !text-left mt-2">
            Personal and other information you may provide when entering
            contests or giveaways and/or responding to surveys.
          </p>
        </div>

        <div className="pp-container-two mt-5">
          <h2 className="title-40 !text-left mb-2">USE OF YOUR INFORMATION</h2>
          <p className="para-25 !text-left mt-3 mb-2">
            Having accurate information about you permits us to provide you with
            a smooth, efficient, and customized experience. Specifically, we may
            use information collected about you via the Site to:
          </p>
          <ul>
            {useOfYourInfo.map((list) => {
              return <li className="para-25 !text-left mt-1">• {list}</li>;
            })}
          </ul>
        </div>

        <div className="pp-container-three mt-5">
          <h2 className="title-40 !text-left mb-2">
            DISCLOSURE OF YOUR INFORMATION
          </h2>
          <p className="para-25 !text-left mt-3">
            We may share information we have collected about you in certain
            situations. Your information may be disclosed as follows:
          </p>
          <h3 className="para-25 !text-left mt-2">
            BY LAW OR TO PROTECT RIGHTS
          </h3>
          <p className="para-25 !text-left mt-2">
            If we believe the release of information about you is necessary to
            respond to legal process, to investigate or remedy potential
            violations of our policies, or to protect the rights, property, and
            safety of others, we may share your information as permitted or
            required by any applicable law, rule, or regulation. This includes
            exchanging information with other entities for fraud protection and
            credit risk reduction.
          </p>
          <h3 className="para-25 !text-left mt-2">
            THIRD-PARTY SERVICE PROVIDERS
          </h3>
          <p className="para-25 !text-left mt-2">
            We may share your information with third parties that perform
            services for us or on our behalf, including payment processing, data
            analysis, email delivery, hosting services, customer service, and
            marketing assistance.
          </p>
          <h3 className="para-25 !text-left mt-2">FINANCIAL DATA</h3>
          <p className="para-25 !text-left mt-2">
            Financial information, such as data related to your payment method
            (e.g. valid credit card number, card brand, expiration date) that we
            may collect when you purchase, order, return, exchange, or request
            information about our services from the Site. [We store only very
            limited, if any, financial information that we collect. Otherwise,
            all financial information is stored by our payment processor,
            [HitPay App], [Stripe Payments], and you are encouraged to review
            their privacy policy and contact them directly for responses to your
            questions.]
          </p>
          <h3 className="para-25 !text-left mt-2">MARKETING COMMUNICATIONS</h3>
          <p className="para-25 !text-left mt-2">
            With an opportunity for you to withdraw consent, we may share your
            information with third parties for marketing purposes, as permitted
            by law.
          </p>
          <h3 className="para-25 !text-left mt-2">
            INTERACTIONS WITH OTHER USERS
          </h3>
          <p className="para-25 !text-left mt-2">
            If you interact with other users of the Site, those users may see
            your name, profile photo, and descriptions of your activity,
            including sending invitations to other users, chatting with other
            users, liking posts, following blogs.
          </p>
          <h3 className="para-25 !text-left mt-2">ONLINE POSTINGS</h3>
          <p className="para-25 !text-left mt-2">
            When you post comments, contributions or other content to the Site,
            your posts may be viewed by all users and may be publicly
            distributed outside the Site in perpetuity.
          </p>
          <h3 className="para-25 !text-left mt-2">THIRD-PARTY ADVERTISERS</h3>
          <p className="para-25 !text-left mt-2">
            We may use third-party advertising companies to serve ads when you
            visit the Site. These companies may use information about your
            visits to the Site and other websites that are contained in web
            cookies in order to provide advertisements about goods and services
            of interest to you.
          </p>
          <h3 className="para-25 !text-left mt-2">AFFILIATES</h3>
          <p className="para-25 !text-left mt-2">
            We may share your information with our affiliates, in which case we
            will require those affiliates to honor this Privacy Policy.
            Affiliates include our parent company and any subsidiaries, joint
            venture partners or other companies that we control or that are
            under common control with us.
          </p>
          <h3 className="para-25 !text-left mt-2">BUSINESS PARTNERS</h3>
          <p className="para-25 !text-left mt-2">
            We may share your information with our business partners to offer
            you certain products, services or promotions.
          </p>
          <h3 className="para-25 !text-left mt-2">OTHER THIRD PARTIES</h3>
          <p className="para-25 !text-left mt-2">
            We may share your information with advertisers and investors for the
            purpose of conducting general business analysis. We may also share
            your information with such third parties for marketing purposes, as
            permitted by law.
          </p>
          <h3 className="para-25 !text-left mt-2">SALE OR BANKRUPTCY</h3>
          <p className="para-25 !text-left mt-2">
            If we reorganize or sell all or a portion of our assets, undergo a
            merger, or are acquired by another entity, we may transfer your
            information to the successor entity. If we go out of business or
            enter bankruptcy, your information would be an asset transferred or
            acquired by a third party. You acknowledge that such transfers may
            occur and that the transferee may decline honour commitments we made
            in this Privacy Policy. We are not responsible for the actions of
            third parties with whom you share personal or sensitive data, and we
            have no authority to manage or control third-party solicitations. If
            you no longer wish to receive correspondence, emails or other
            communications from third parties, you are responsible for
            contacting the third party directly.
          </p>
        </div>

        <div className="pp-container-four mt-5">
          <h2 className="title-40 !text-left mb-2">TRACKING TECHNOLOGIES</h2>

          <h3 className="para-25 !text-left mt-2">COOKIES AND WEB BEACONS</h3>
          <p className="para-25 !text-left mt-2">
            [We may use cookies, web beacons, tracking pixels, and other
            tracking technologies on the Site to help customize the Site and
            improve your experience. When you access the Site, your personal
            information is not collected through the use of tracking technology.
            Most browsers are set to accept cookies by default. You can remove
            or reject cookies, but be aware that such action could affect the
            availability and functionality of the Site. You may not decline web
            beacons. However, they can be rendered ineffective by declining all
            cookies or by modifying your web browser’s settings to notify you
            each time a cookie is tendered, permitting you to accept or decline
            cookies on an individual basis.] [We may use cookies, web beacons,
            tracking pixels, and other tracking technologies on the Site [and
            our mobile application] to help customize the Site [and our mobile
            application] and improve your experience. For more information on
            how we use cookies, please refer to our Cookie Policy posted on the
            Site, which is incorporated into this Privacy Policy. By using the
            Site, you agree to be bound by our Cookie Policy.]
          </p>
          <h3 className="para-25 !text-left mt-2">
            INTERNET-BASED ADVERTISING
          </h3>
          <p className="para-25 !text-left mt-2">
            Additionally, we may use third-party software to serve ads on the
            Site, implement email marketing campaigns, and manage other
            interactive marketing initiatives. This third-party software may use
            cookies or similar tracking technology to help manage and optimize
            your online experience with us. For more information about
            opting-out of interest-based ads, visit theNetwork Advertising
            Initiative Opt-Out ToolorDigital Advertising Alliance Opt-Out Tool.
          </p>
          <h3 className="para-25 !text-left mt-2">
            WEBSITE AND EMAIL ANALYTICS
          </h3>
          <p className="para-25 !text-left mt-2">
            We may also partner with selected third-party vendors[, such as
            [Google Analytics], to allow tracking technologies and remarketing
            services on the Site through the use of first party cookies and
            third-party cookies, to, among other things, analyze and track
            users’ use of the Site, determine the popularity of certain content
            and better understand online activity. By accessing the Site, you
            consent to the collection and use of your information by these
            third-party vendors. You are encouraged to review their privacy
            policy and contact them directly for responses to your questions. We
            do not transfer personal information to these third-party vendors.
            However, if you do not want any information to be collected and used
            by tracking technologies, you can visit the third-party vendor or
            the Network Advertising Initiative Opt-Out ToolorDigital Advertising
            Alliance Opt-Out Tool. You should be aware that getting a new
            computer, installing a new browser, upgrading an existing browser,
            or erasing or otherwise altering your browser’s cookies files may
            also clear certain opt-out cookies, plug-ins, or settings.
          </p>
        </div>

        <div className="pp-container-four mt-5">
          <h2 className="title-40 !text-left mb-2">THIRD-PARTY WEBSITES</h2>
          <p className="para-25 !text-left mt-3">
            The Site may contain links to third-party websites and applications
            of interest, including advertisements and external services, that
            are not affiliated with us. Once you have used these links to leave
            the Site, any information you provide to these third parties is not
            covered by this Privacy Policy, and we cannot guarantee the safety
            and privacy of your information. Before visiting and providing any
            information to any third-party websites, you should inform yourself
            of the privacy policies and practices (if any) of the third party
            responsible for that website, and should take those steps necessary
            to, in your discretion, protect the privacy of your information. We
            are not responsible for the content or privacy and security
            practices and policies of any third parties, including other sites,
            services or applications that may be linked to or from the Site.
          </p>
          <h3 className="title-40 !text-left mb-2 mt-3">
            SECURITY OF YOUR INFORMATION
          </h3>
          <p className="para-25 !text-left mt-3">
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are perfect or impenetrable, and no method of data transmission can
            be guaranteed against any interception or other type of misuse. Any
            information disclosed online is vulnerable to interception and
            misuse by unauthorized parties. Therefore, we cannot guarantee
            complete security if you provide personal information.
          </p>
          <h3 className="title-40 !text-left mb-2 mt-3">POLICY FOR CHILDREN</h3>
          <p className="para-25 !text-left mt-3">
            We do not knowingly solicit information from or market to children
            under the age of 13. If you become aware of any data we have
            collected from children under age 13, please contact us using the
            contact information provided below.
          </p>
          <h3 className="title-40 !text-left mb-2 mt-3">
            CONTROLS FOR DO-NOT-TRACK FEATURES
          </h3>
          <p className="para-25 !text-left mt-3">
            Most web browsers include a Do-Not-Track feature or setting you can
            activate to signal your privacy preference not to have data about
            your online browsing activities monitored and collected. No uniform
            technology standard for recognizing and implementing DNT signals has
            been finalized. As such, we do not currently respond to DNT browser
            signals or any other mechanism that automatically communicates your
            choice not to be tracked online. If a standard for online tracking
            is adopted that we must follow in the future, we will inform you
            about that practice in a revised version of this Privacy
            Policy./Most web browsers and some mobile operating systems include
            a Do-Not-Track (DNT) feature or setting you can activate to signal
            your privacy preference not to have data about your online browsing
            activities monitored and collected. If you set the DNT signal on
            your browser, we will respond to such DNT browser signals.
          </p>
          <h3 className="title-40 !text-left mb-2 mt-3">
            OPTIONS REGARDING YOUR INFORMATION
          </h3>
          <p className="para-25 !text-left mt-3">
            You may at any time review or change the information in your account
            or terminate your account by:
          </p>
          <p className="para-25 !text-left mt-3">
            • Logging into your account settings and updating your account
          </p>
          <p className="para-25 !text-left mt-1">
            • Contacting us using the contact information provided below
          </p>
          <p className="para-25 !text-left mt-3">
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, some information may be retained in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with legal requirements.
          </p>

          <h3 className="para-25 !text-left mt-2">EMAILS AND COMMUNICATIONS</h3>
          <p className="para-25 !text-left mt-3">
            If you no longer wish to receive correspondence, emails, or other
            communications from us, you may opt-out by:
          </p>
          <p className="para-25 !text-left mt-3">
            • Option to Unsubscribe in the email.
          </p>
          <p className="para-25 !text-left mt-1">
            • Contacting us using the contact information provided below
          </p>
          <p className="para-25 !text-left mt-3">
            If you no longer wish to receive correspondence, emails, or other
            communications from third parties, you are responsible for
            contacting the third party directly.
          </p>
          <h3 className="title-40 !text-left mb-2 mt-3">CONTACT US</h3>
          <p className="para-25 !text-left mt-3">
            If you have questions or comments about this Privacy Policy, please
            contact us at:
          </p>
          <h3 className="title-40 !text-left mb-2 mt-3 !tracking-wider">
            PINCH BAKEHOUSE
          </h3>
        </div>
      </div>

      <ChoosePinchBakehouse />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
