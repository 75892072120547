import Footer from "components/modules/Footer/Footer";
import { HOME } from "components/utils/constants/routeConstants";
import { useNavigate } from "react-router-dom";
import GenericButton from "./genericButton/GenericButton";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-primary-cream h-[500px] md:h-[720px] flex flex-col items-center justify-center">
        <img
          className=" h-[300px] w-[300px]  md:h-[450px] md:w-[450px]"
          src="/assets/images/svg/404.svg"
          alt="cake-loader"
        />
        <div className="flex items-center flex-col gap-1">
          <p className="font-display text-[2.2rem] tracking-tight  md:text-[3rem] font-semibold">
            Page Not Found
          </p>
          <p className="font-inter text-[0.9rem] md:text-[1.1rem] font-normal mb-5">
            The page you requested was not found.
          </p>
          <GenericButton
            btnLabel="Go Home"
            onClick={() => {
              navigate(HOME);
            }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PageNotFound;
