import Proptypes from "prop-types";
import "./CommonVioletHeader.css";

const CommonVioletHeader = ({
  title,
  backLinkName,
  href,
  onClick,
  otherContent,
}) => {
  return (
    <header className="bg-primary-violet-20 h-[200px] relative ">
      <button
        type="button"
        href={href}
        className="absolute right-7 md:right-20 top-3 sm-text-18 underline !font-[500] underline-style-under !cursor-pointer z-10 hover:text-[#7f7f7f]"
        onClick={onClick}
      >
        {backLinkName}
      </button>
      <div className="flex items-center justify-center h-full  relative flex-col ">
        <h1 className="title-60">{title}</h1>

        <div className="mt-2">{otherContent}</div>
      </div>
    </header>
  );
};

CommonVioletHeader.defaultProps = {
  href: "",
};

CommonVioletHeader.propTypes = {
  title: Proptypes.string.isRequired,
  backLinkName: Proptypes.string.isRequired,
  href: Proptypes.string,
  onClick: Proptypes.func.isRequired,
  otherContent: Proptypes.string.isRequired,
};

export default CommonVioletHeader;
