import {
  DESKTOP_MIN_WIDTH,
  IPAD_MIN_WIDTH,
} from "components/utils/constants/messageConstants";
import { useEffect, useState } from "react";

const useCheckDevice = () => {
  const [isDesktop, setIsDesktop] = useState(true);
  const [isIpad, setIsIpad] = useState(false);

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    setIsDesktop(screenWidth > DESKTOP_MIN_WIDTH);
    // ipad screen size
    if (screenWidth <= DESKTOP_MIN_WIDTH && screenWidth >= IPAD_MIN_WIDTH) {
      setIsIpad(true);
    } else {
      setIsIpad(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [isDesktop, isIpad];
};

export default useCheckDevice;
