import {
  CUSTOMISED_CAKES_BLOG,
  CUSTOMISED_CAKES_BLOG_1,
  CUSTOMISED_CAKES_BLOG_2,
  CUSTOMISED_CAKES_BLOG_3,
  CUSTOMISED_CAKES_BLOG_4,
} from "components/utils/constants/urlConstants";
import "./Articles.css";
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";

const ArticleFour = () => {
  return (
    <>
      <Helmet>
        <title>
          Halal Cakes - Cake Trends Spotted. What Cake Design Should You
          Customise for Your Next Party?
        </title>
        <meta
          name="description"
          content="Hey cake lovers! If you’re anything like me, you’re always on the lookout for the latest and greatest cake trends. Whether it's for birthdays, celebrations, or just because, a trendy cake can make any occasion extra special. Today, I’m spilling the tea on the top 5 cake trends you’ll soon see all over social media. And trust me, as someone from the top halal cake shop in Singapore, Pinch Bakehouse, I’ve got all the insider info! Let’s dive into the sweetest trends taking over the cake world."
        />
      </Helmet>
      <div className="m-auto max-w-[900px] bg-primary-cream-20 px-8 py-10 lg:py-20 lg:px-0 flex items-center justify-center flex-col">
        <div className="max-w-[750px] mb-6">
          <h1 className="blog-title-60 !text-left ">
            Halal Cakes - Cake Trends Spotted. What Cake Design Should You
            Customise for Your Next Party?
          </h1>
          <p className="para-25 !text-left mt-6">
            Hey cake lovers! If you&apos;re anything like me, you&apos;re always
            on the lookout for the latest and greatest cake trends. Whether it’s
            for birthdays, celebrations, or just because, a trendy cake can make
            any occasion extra special. Today, I’m spilling the tea on the top 5
            cake trends you’ll soon see all over social media. And trust me, as
            someone from the top halal cake shop in Singapore, Pinch Bakehouse,
            I’ve got all the insider info! Let’s dive into the sweetest trends
            taking over the cake world.
          </p>
          <img
            src={CUSTOMISED_CAKES_BLOG}
            alt="halal-cake"
            className="object-cover w-full h-[400px] lg:w-[600px] lg:h-[600px] m-auto my-8"
          />
          <h2 className="title-40 !text-left mt-6">1. Wishing Well Cake</h2>
          <p className="para-25 !text-left mt-6">
            First up, we have the magical wishing well cake. Now… this is
            actually my favourite of the five trending designs in 2024. These
            cakes are designed to look like enchanting wishing wells, complete
            with a blue edible wishing well transparent pool surrounded by
            dreamy edible flowers. It’s every dreamer’s and fantasy lover’s
            dream come true. Imagine the look on your friend’s face when they
            see this stunning cake at their party. It’s like stepping into a
            fairy tale!
          </p>
          <img
            src={CUSTOMISED_CAKES_BLOG_1}
            alt="halal-cake"
            className="object-cover w-full h-[400px] lg:w-[600px] lg:h-[600px] m-auto my-8"
          />
          <h2 className="title-40 !text-left mt-6">2. Anything Cute Bear</h2>
          <p className="para-25 !text-left mt-6">
            Next, we’ve got a trend that’s absolutely adorable: anything cute
            bear. From teddy bear toppers to bear-shaped cakes, these sweet
            treats are stealing hearts everywhere. Whether it’s for a baby
            shower, a kid’s birthday, or just a bear lover’s celebration, these
            cakes are as cute as they come. Think fluffy fur textures, playful
            expressions, and a whole lot of charm.
          </p>

          <img
            src={CUSTOMISED_CAKES_BLOG_2}
            alt="halal-cake"
            className="object-cover w-full h-[400px] lg:w-[600px] lg:h-[600px] m-auto my-8"
          />
          <h2 className="title-40 !text-left mt-6">3. Jellycat 3D Cake</h2>
          <p className="para-25 !text-left mt-6">
            If you’re a fan of Jellycat plush toys, you’re going to love this
            trend. The Jellycat 3D cake is all about recreating those beloved,
            super soft stuffed animals in cake form. These cakes are incredibly
            detailed, capturing the huggable essence of Jellycat toys. They’re
            perfect for kids (and adults) who adore their cuddly friends and
            want to see them in a sweet, edible version.
          </p>
          <img
            src={CUSTOMISED_CAKES_BLOG_3}
            alt="halal-cake"
            className="object-cover w-full h-[400px] lg:w-[600px] lg:h-[600px] m-auto my-8"
          />
          <h2 className="title-40 !text-left mt-6">
            4. Butterfly Shaped Cakes
          </h2>
          <p className="para-25 !text-left mt-6">
            Butterflies have always been a symbol of transformation and beauty,
            and now they’re fluttering their way into the cake world. Butterfly
            shaped cakes are elegant and whimsical, making them perfect for
            everything from garden parties to whimsical weddings. Imagine a cake
            shaped like a butterfly, with intricate designs and vibrant
            colors—it’s almost too pretty to eat!
          </p>
          <img
            src={CUSTOMISED_CAKES_BLOG_4}
            alt="halal-cake"
            className="object-cover w-full h-[400px] lg:w-[600px] lg:h-[600px] m-auto my-8"
          />
          <h2 className="title-40 !text-left mt-6">
            5. Oreo and Speculoos Monster Minimalist Cakes
          </h2>
          <p className="para-25 !text-left mt-6">
            Last but definitely not least, we have the Oreo and Speculoos
            Monster minimalist cakes. These cakes are a minimalist’s dream, with
            simple yet striking designs featuring everyone’s favorite cookies.
            Think sleek black and white themes for Oreo lovers and warm, spiced
            tones for Speculoos fans. They’re not just tasty—they’re
            Instagram-worthy too!
          </p>
          <h2 className="title-40 !text-left mt-6">Disclaimer</h2>
          <p className="para-25 !text-left mt-6">
            Now, a little disclaimer: while these fabulous cake trends are not
            originally done by us at Pinch Bakehouse, we’re more than ready to
            recreate your dream cake designs. After all, who doesn’t want the
            most trending cake designs for their birthday?
          </p>
          <p className="para-25 !text-left mt-6">
            We’re committed to inclusivity, quality, and making every
            celebration special. So, whether you’re looking for a halal cake, a
            gluten-free treat, or a custom pastry, come on over to Pinch
            Bakehouse. We can’t wait to be a part of your celebrations and share
            a little pinch of love with you.
          </p>
          <p className="para-25 !text-left mt-6">
            Remember, finding the best halal cakes in Singapore doesn’t have to
            be a hassle anymore. With Pinch Bakehouse, you get delicious,
            halal-certified, and beautifully packaged cakes that everyone can
            enjoy. So, what are you waiting for? Let’s make your next
            celebration sweeter and more inclusive with Pinch Bakehouse’s
            delightful halal cakes. See you soon!
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleFour;
