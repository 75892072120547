export const HOME = "/";
export const ALL_CAKES = "/all-cakes";
export const ALL_BAKES = "/all-bakes";
export const CAKE_DETAILS = "/details/:id";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const ACCOUNT = "/account";
export const BLOGS = "/blogs";
export const ADDRESS_BOOK = "/address";
export const CHANGE_PWD = "/change-password";
export const RESET_PWD = "/reset-password/:id";
export const FORGOT_PWD = "/forgot-password";
export const CAKE_CONCIERGE = "/cake-concierge";
export const CART_PAGE = "/cart";
export const ORDER_CONFIRMATION = "/order-confirm/:id";
export const CHECKOUT = "/checkout";
export const PAYMENT_FAIL = "/retry-payment/:id";
export const ORDER_DETAILS = "/order/:id";
export const FAQ = "/frequently-asked-questions";
export const CONTACT_US = "/contact-us";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_CONDITIONS = "/terms-conditions";

// Articles urls
export const ARTICLE_ONE = "/best-halal-cakes-singapore";
export const ARTICLE_TWO = "/cake-halal-certification";
export const ARTICLE_THREE = "/dessert-Fix-pinch-bakehouse";
export const ARTICLE_FOUR = "/halal-trends-spotted";
export const ARTICLE_FIVE = "/sweet-world-halal-baking-pastries";
