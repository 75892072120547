/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { findIndex, isEqual, omit } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { GIFT_CARD_PRICE } from "components/utils/constants/messageConstants";

const initialState = {
  product: [],
  delivery: {
    date: "",
    method: {
      id: "",
      value: "",
      price: 0,
    },
    collectionTime: "",
    address: {
      id: "",
      value: "",
      name: "",
      phone: "",
      postalCode: "",
    },
  },
  pricingSummary: {
    subTotal: 0,
    gst: 0,
    deliveryCharge: 0,
    coupon: {
      id: "",
      code: "",
      discount: 0,
      discountedAmount: 0,
      uniqueCategoryIds: [],
    },
    total: 0,
  },
  fbt: [],
};

const calculateSubTotal = (state) => {
  const productPrices = state.product?.map((d) => d.price);
  const subTotal = productPrices.reduce((acc, curr) => acc + curr, 0);

  state.pricingSummary = {
    ...state.pricingSummary,
    subTotal,
    total: subTotal + state.pricingSummary.deliveryCharge,
  };
  return state.pricingSummary;
};

export const cartDetailSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    addFbtCartList: (state, { payload }) => {
      state.fbt = [...state.fbt, ...payload];
    },
    removeFbtCartList: (state, { payload }) => {
      state.fbt = state.fbt?.filter((d) => d.id !== payload);
    },
    addDeliveryAddress: (state, { payload }) => {
      state.delivery = {
        ...state.delivery,
        address: payload,
      };
    },
    addDeliveryCharges: (state, { payload }) => {
      state.pricingSummary = {
        ...state.pricingSummary,
        deliveryCharge: payload,
        total:
          state.pricingSummary.subTotal +
          payload -
          state.pricingSummary.coupon.discountedAmount,
      };
    },
    addDeliveryDate: (state, { payload }) => {
      state.delivery = { ...state.delivery, date: payload };
    },
    addDeliveryMethod: (state, { payload }) => {
      state.delivery = { ...state.delivery, method: payload };
    },
    addCollectionTime: (state, { payload }) => {
      state.delivery = { ...state.delivery, collectionTime: payload };
    },
    deleteProduct: (state, action) => {
      const index = action.payload;
      delete state.product[index];
      state.product = state.product.filter((d) => d);
      calculateSubTotal(state);
      if (!state.product.length) {
        state.product = [];
        state.delivery = initialState.delivery;
        state.pricingSummary = initialState.pricingSummary;
      }
    },
    updateCart: (state, action) => {
      const { index, behaviour, msg, fondName, fondNumber } = action.payload;

      const product = state.product[index];
      // updating quantity
      if (behaviour === "plus") {
        product.quantity++;
      } else if (behaviour === "minus") {
        product.quantity--;
      }
      // updating price
      if (product.size) {
        product.price = +product.pricePerSize * +product.quantity;
      } else if (product.pieces) {
        product.price = +product.pricePerPiece * +product.quantity;
      } else {
        product.price = +product.originalPrice * +product.quantity;
      }
      // updating message
      if (msg || msg === "") product.msg = msg;
      // updating fondant name
      if (fondName || fondName === "") product.fondName = fondName;
      // updating fondant number
      if (fondNumber || fondNumber === "") product.fondNumber = fondNumber;
      // updating price after message
      if (product.msg) {
        product.price += GIFT_CARD_PRICE * +product.quantity;
      }
      calculateSubTotal(state);
    },
    addProduct: (state, { payload }) => {
      const productToAdd = payload;
      // Find all products with the same ID as the product to add
      const existingProducts = state.product.filter(
        (product) => product.id === productToAdd.id,
      );

      if (existingProducts.length) {
        const propertyToSkip = ["quantity", "price"];
        const existingProductIndex = existingProducts
          .map((existingProduct) => {
            // Check if any of the existing product is equal to the productToAdd - only 1 can be equal
            const omittedExistingProduct = omit(
              existingProduct,
              propertyToSkip,
            );
            const omittedProductToAdd = omit(productToAdd, propertyToSkip);
            let existingProductIndex = null;
            const matched = isEqual(
              omittedExistingProduct,
              omittedProductToAdd,
            );
            if (matched) {
              // Find index of existing product whose all properties matches with productToAdd
              existingProductIndex = findIndex(state.product, existingProduct);
            }
            return existingProductIndex;
          })
          .filter((d) => d !== null);

        if (existingProductIndex.length) {
          state.product[existingProductIndex].quantity += productToAdd.quantity;
          state.product[existingProductIndex].price += productToAdd.price;
        } else {
          // If any one property of productToAdd is different from all the existing products of same id, create a new entry
          state.product = [...state.product, productToAdd];
        }
      } else {
        // Product does not exist in the cart, create a new entry
        state.product = [...state.product, productToAdd];
      }
      calculateSubTotal(state);
    },
    applyCoupon: (state, { payload }) => {
      const subTotalAfterDiscount =
        state.pricingSummary.subTotal - payload.discountedAmount;
      state.pricingSummary = {
        ...state.pricingSummary,
        coupon: payload,
        total:
          subTotalAfterDiscount + state.pricingSummary.deliveryCharge > 0
            ? subTotalAfterDiscount + state.pricingSummary.deliveryCharge
            : 0,
      };
    },
    removeCoupon: (state) => {
      const subTotalAfterDiscount =
        state.pricingSummary.subTotal -
        initialState.pricingSummary.coupon.discountedAmount;
      state.pricingSummary = {
        ...state.pricingSummary,
        coupon: initialState.pricingSummary.coupon,
        total: subTotalAfterDiscount + state.pricingSummary.deliveryCharge,
      };
    },
  },
});

export const {
  removeCoupon,
  applyCoupon,
  deleteProduct,
  addProduct,
  updateCart,
  addDeliveryDate,
  addDeliveryMethod,
  addCollectionTime,
  addDeliveryCharges,
  addDeliveryAddress,
  addFbtCartList,
  removeFbtCartList,
} = cartDetailSlice.actions;
export default cartDetailSlice.reducer;
