import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartSidebar: false,
};

export const cartSlice = createSlice({
  name: "cartSidebar",
  initialState,
  reducers: {
    cart: (state, { payload }) => {
      const cartState = state;

      cartState.cartSidebar = payload;
    },
  },
});

export const { cart } = cartSlice.actions;
export default cartSlice.reducer;
