import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toggleSidebar: false,
};

export const sidebarSlice = createSlice({
  name: "toggleSidebar",
  initialState,
  reducers: {
    toggle: (state, { payload }) => {
      const sidebarState = state;
      sidebarState.toggleSidebar = payload;
    },
  },
});

export const { toggle } = sidebarSlice.actions;
export default sidebarSlice.reducer;
